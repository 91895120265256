import React, { useState } from 'react';
import { ArrowRight, Send, CheckCircle } from 'lucide-react';

const EventFormPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    subject: 'AWS Event Beta Access Request',
    message: '',
    selectedPlan: 'Event'
  });
  const [formState, setFormState] = useState('form'); // 'form', 'sending', 'success', 'error'

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormState('sending');

    try {
      const response = await fetch('https://tuj26fokhl.execute-api.us-east-2.amazonaws.com/VeriloContactUs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      
      if (response.ok) {
        setFormState('success');
        setFormData({ name: '', email: '', company: '', subject: '', message: '', selectedPlan: '' });
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to submit form');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setFormState('error');
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100">
      <header className="bg-gray-800 bg-opacity-90 backdrop-filter backdrop-blur-lg py-3">
        <div className="max-w-6xl mx-auto px-4">
          <div className="flex items-center">
            <img 
              src="veriloLogo512.png" 
              alt="Verilo Logo" 
              className="w-8 h-8 mr-2"
            />
            <span className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-purple-400">
              Verilo
            </span>
          </div>
        </div>
      </header>

      <main className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center mb-12">
          <div className="bg-indigo-500 text-white text-sm font-semibold px-3 py-1 rounded-full inline-block mb-4">
            Limited Time Offer
          </div>
          <h1 className="text-4xl font-extrabold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-purple-400">
            Apply for 45 Day Free Beta Access
          </h1>
          <p className="text-xl text-gray-300 max-w-2xl mx-auto">
            Transform your hiring process with AI-powered interview analysis
          </p>
        </div>

        <div className="bg-gray-800 rounded-lg p-8 shadow-xl relative overflow-hidden min-h-[500px]">
          {/* Form State */}
          <div
            className={`transition-all duration-500 ease-in-out absolute inset-0 p-8
              ${formState === 'form' ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'}`}
          >
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-300 mb-1">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                  className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-1">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
              </div>
              <div>
                <label htmlFor="company" className="block text-sm font-medium text-gray-300 mb-1">Company</label>
                <input
                  type="text"
                  id="company"
                  name="company"
                  value={formData.company}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
              </div>
              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-300 mb-1">Message</label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  value={formData.message}
                  onChange={handleInputChange}
                  required
                  className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
              </div>
              <button
                type="submit"
                className="w-full bg-gradient-to-r from-indigo-500 to-purple-600 text-white font-semibold py-3 px-4 rounded-md hover:from-indigo-600 hover:to-purple-700 transition flex items-center justify-center"
              >
                Apply for Early Access
                <Send className="ml-2 h-5 w-5" />
              </button>
            </form>
          </div>

          {/* Loading State */}
          <div
            className={`transition-all duration-500 ease-in-out absolute inset-0 flex items-center justify-center
              ${formState === 'sending' ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'}`}
          >
            <div className="text-center">
              <div className="w-16 h-16 border-4 border-indigo-400 border-t-transparent rounded-full animate-spin mb-4" />
              <p className="text-lg text-gray-300">Sending your request...</p>
            </div>
          </div>

          {/* Success State */}
          <div
            className={`transition-all duration-500 ease-in-out absolute inset-0 flex items-center justify-center p-8
              ${formState === 'success' ? 'translate-x-0 opacity-100' : '-translate-x-full opacity-0'}`}
          >
            <div className="text-center">
              <div className="w-16 h-16 mx-auto mb-4 text-green-400">
                <CheckCircle className="w-full h-full" />
              </div>
              <h3 className="text-2xl font-bold text-gray-100 mb-4">Thank You!</h3>
              <p className="text-lg text-gray-300 mb-6">
                Your request has been received. We'll be in touch soon to get you started with your beta access.
              </p>
              <div className="p-4 bg-gray-700 rounded-lg">
                <p className="text-sm text-gray-400">
                  Keep an eye on your inbox for next steps. If you don't receive an email within 24 hours, please check your spam folder.
                </p>
              </div>
            </div>
          </div>

          {/* Error State */}
          <div
            className={`transition-all duration-500 ease-in-out absolute inset-0 flex items-center justify-center p-8
              ${formState === 'error' ? 'translate-x-0 opacity-100' : '-translate-x-full opacity-0'}`}
          >
            <div className="text-center">
              <div className="w-16 h-16 mx-auto mb-4 text-red-400">
                <svg className="w-full h-full" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <h3 className="text-2xl font-bold text-gray-100 mb-4">Something Went Wrong</h3>
              <p className="text-lg text-gray-300 mb-6">
                We couldn't process your request. Please try again later or contact support.
              </p>
              <button
                onClick={() => setFormState('form')}
                className="bg-gray-700 text-white px-6 py-2 rounded-md hover:bg-gray-600 transition"
              >
                Try Again
              </button>
            </div>
          </div>
        </div>

        <div className="mt-8 text-center">
          <p className="text-gray-400 text-sm">
            Your information is secure and will not be shared
          </p>
        </div>
      </main>
    </div>
  );
};

export default EventFormPage;
import React from 'react';
import { ArrowRight, UserCheck, DollarSign, Zap, TrendingUp, Upload, BarChart2, CheckCircle, AlertCircle } from 'lucide-react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar.tsx';
import ValueCalculator from './ValueCalculator.tsx';

const BenefitsPage = () => {
  const benefits = [
    {
      icon: UserCheck,
      title: "Better Hiring Decisions",
      description: "Our AI analyzes confidence levels and flags potential issues, leading to smarter candidate selections. Empower your team to make informed hiring decisions.",
    },
    {
      icon: DollarSign,
      title: "Cost Savings",
      description: "Make the right hire, the first time. Avoid costly rehires and repeated onboarding. On average, companies save 3 to 4 times an employee's salary by getting it right initially.",
    },
    {
      icon: Zap,
      title: "Seamless Integration",
      description: "Simply upload your interview recordings, recorded in person or online. No special equipment or scripts needed. No additional interview questions or HR processes need to be in place.",
    },
    {
      icon: TrendingUp,
      title: "Data-Driven Insights",
      description: "Make informed decisions based on objective, AI-powered analysis of candidate responses. Our algorithm provides a 70% accuracy rate compared to the human average of 49%.",
    },
  ];

  const steps = [
    { icon: Upload, title: "Upload", description: "Submit your interview recordings to our secure platform." },
    { icon: BarChart2, title: "Analyze", description: "Our AI assesses candidate confidence and flags potential concerns." },
    { icon: CheckCircle, title: "Decide", description: "Review our comprehensive report and make informed hiring decisions." },
  ];

  const faqs = [
    {
      question: "How does Verilo's AI analyze interviews?",
      answer: "Verilo's AI uses advanced natural language processing and machine learning algorithms to analyze speech patterns, word choice, and other linguistic cues that indicate confidence levels during interviews."
    },
    {
      question: "Is Verilo compatible with our existing hiring process?",
      answer: "Yes, Verilo is designed to seamlessly integrate with your current hiring process. You can simply upload your existing interview recordings, whether they're from in-person or online interviews. No extra scripts or interview questions need to be in place."
    },
    {
      question: "How accurate is Verilo compared to human interviewers?",
      answer: "Verilo has a 70% accuracy rate in detecting confidence in applicants during interviews, compared to the human average of 49%. This significant improvement helps companies make more informed hiring decisions."
    },
    {
      question: "Can Verilo help reduce hiring costs?",
      answer: "Yes, by improving the accuracy of hiring decisions, Verilo can help reduce the costs associated with bad hires. A bad hire is estimated to cost a company 3-4 times the employee's salary, so making better hiring decisions can lead to substantial savings."
    },
    {
      question: "Is our interview data secure with Verilo?",
      answer: "Absolutely. We take data security very seriously. All interview data is encrypted both in transit and at rest, and we follow all industry standard practices for data protection."
    }
  ];

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100">
      <Navbar />

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-24">
        <section className="py-20">
          <h1 className="text-4xl md:text-5xl font-extrabold mb-6 leading-tight text-center text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-purple-400">
            Transform Your Hiring Process with Verilo
          </h1>
        </section>

        <section className="mb-20">
          <ValueCalculator />
          
        </section>

        <section className="mb-20">
          <h2 className="text-3xl font-bold text-center mb-12">Key Benefits</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {benefits.map((benefit, index) => (
              <div key={index} className="bg-gray-800 rounded-lg p-6">
                <div className="bg-indigo-600 rounded-full p-3 inline-block mb-4">
                  <benefit.icon className="h-6 w-6 text-white" />
                </div>
                <h3 className="text-xl font-semibold mb-2">{benefit.title}</h3>
                <p className="text-gray-300">{benefit.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="mb-20">
          <h2 className="text-3xl font-bold text-center mb-12">How Verilo Works</h2>
          <div className="bg-gray-800 rounded-lg p-8">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {steps.map((step, index) => (
                <div key={index} className="text-center">
                  <div className="bg-indigo-600 rounded-full p-3 inline-block mb-4">
                    <step.icon className="h-6 w-6 text-white" />
                  </div>
                  <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
                  <p className="text-gray-300">{step.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="mb-20">
          <h2 className="text-3xl font-bold text-center mb-12">Frequently Asked Questions</h2>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <details key={index} className="bg-gray-800 rounded-lg">
                <summary className="cursor-pointer p-4 text-gray-100 hover:text-indigo-400">
                  {faq.question}
                </summary>
                <p className="p-4 bg-gray-700 text-gray-300">
                  {faq.answer}
                </p>
              </details>
            ))}
          </div>
        </section>

        <section className="py-20 text-center bg-gray-800 rounded-lg mt-20"> {/* Added mt-20 for top margin */}
          <h2 className="text-3xl font-bold mb-6">Ready to Transform Your Hiring?</h2>
          <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
            Join the companies saving millions in hiring costs and building stronger teams with Verilo.
          </p>
          <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
            <Link to="/contact" className="bg-indigo-600 text-white text-lg font-semibold py-3 px-8 rounded-lg hover:bg-indigo-700 transition flex items-center justify-center">
              Schedule a Demo
              <ArrowRight className="ml-2 h-5 w-5" />
            </Link>
            
          </div>
        </section>
      </main>
    </div>
  );
};

export default BenefitsPage;
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Send } from 'lucide-react';
import { InlineWidget } from 'react-calendly';
import Navbar from './Navbar.tsx';

const ContactPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    subject: '',
    message: '',
    selectedPlan: ''
  });
  const [responseMessage, setResponseMessage] = useState({ text: '', type: '' });

  // Replace this URL with your actual Calendly link
  const CALENDLY_URL = 'https://calendly.com/verilo-meeting';

  useEffect(() => {
    if (location.state && location.state.selectedPlan) {
      setFormData(prevData => ({
        ...prevData,
        subject: `Beta Access Request for ${location.state.selectedPlan} Plan`,
        selectedPlan: location.state.selectedPlan
      }));
    }
  }, [location]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const showSuccessMessage = (message) => {
    setResponseMessage({ text: message, type: 'success' });
  };

  const showErrorMessage = (message) => {
    setResponseMessage({ text: message, type: 'error' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setResponseMessage({ text: 'Sending... Please wait.', type: 'info' });

    try {
      const response = await fetch('https://tuj26fokhl.execute-api.us-east-2.amazonaws.com/VeriloContactUs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      
      if (response.ok) {
        showSuccessMessage('Thank you for your message. We will get back to you soon!');
        setFormData({ name: '', email: '', company: '', subject: '', message: '', selectedPlan: '' });
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to submit form');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      showErrorMessage('There was an error submitting your message. Please try again later.');
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100">
      <Navbar />

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="text-center mb-16">
          <h1 className="text-4xl md:text-5xl font-extrabold mb-6 leading-tight text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-purple-400">
            Get in Touch
          </h1>
          <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
            Have questions about Verilo? Choose how you'd like to connect with us below.
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          {/* Calendly Widget */}
          <div className="bg-gray-800 rounded-lg p-6 border border-gray-700">
            <h2 className="text-2xl font-bold mb-4 text-gray-100">Schedule a Meeting</h2>
            <p className="text-gray-300 mb-6">Book a time to speak with our team directly about your needs.</p>
            <div className="min-h-[850px] w-full rounded-lg overflow-hidden">
              <InlineWidget
                url={CALENDLY_URL}
                styles={{
                  height: '850px',
                  width: '100%',
                  overflow: 'hidden'
                }}
                prefill={{}}
                pageSettings={{
                  backgroundColor: '1a1a1a',
                  hideEventTypeDetails: false,
                  hideLandingPageDetails: false,
                  primaryColor: '6366f1',
                  textColor: 'ffffff'
                }}
              />
            </div>
          </div>

          {/* Contact Form */}
          <div className="bg-gray-800 rounded-lg p-6 border border-gray-700 h-full">
            <h2 className="text-2xl font-bold mb-6 text-gray-100">Send Us a Message</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-300 mb-1">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                  className="w-full px-3 py-2 bg-gray-900 border border-gray-700 rounded-md text-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-1">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  className="w-full px-3 py-2 bg-gray-900 border border-gray-700 rounded-md text-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
              </div>
              <div>
                <label htmlFor="company" className="block text-sm font-medium text-gray-300 mb-1">Company</label>
                <input
                  type="text"
                  id="company"
                  name="company"
                  value={formData.company}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 bg-gray-900 border border-gray-700 rounded-md text-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
              </div>
              <div>
                <label htmlFor="subject" className="block text-sm font-medium text-gray-300 mb-1">Subject</label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleInputChange}
                  required
                  className="w-full px-3 py-2 bg-gray-900 border border-gray-700 rounded-md text-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
              </div>
              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-300 mb-1">Message</label>
                <textarea
                  id="message"
                  name="message"
                  rows="6"
                  value={formData.message}
                  onChange={handleInputChange}
                  required
                  className="w-full px-3 py-2 bg-gray-900 border border-gray-700 rounded-md text-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                ></textarea>
              </div>
              <button
                type="submit"
                className="w-full bg-gradient-to-r from-indigo-500 to-purple-600 text-white font-semibold py-3 px-4 rounded-md hover:from-indigo-600 hover:to-purple-700 transition flex items-center justify-center"
              >
                Send Message
                <Send className="ml-2 h-5 w-5" />
              </button>
            </form>
            {responseMessage.text && (
              <div className={`mt-4 p-4 rounded-md ${responseMessage.type === 'success' ? 'bg-green-100 text-green-700' : responseMessage.type === 'error' ? 'bg-red-100 text-red-700' : 'bg-blue-100 text-blue-700'}`}>
                {responseMessage.text}
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default ContactPage;
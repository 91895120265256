import React, { useState, useRef } from 'react';
import { ArrowRight, Upload, FileText, BarChart2, CheckCircle, DollarSign, Menu, X, ChevronLeft, ChevronRight, Shield, Play } from 'lucide-react';
import { Link } from 'react-router-dom';
import TestimonialsSection from './TestimonialsSection.tsx';
import Navbar from './Navbar.tsx';

const LandingPage = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const heroRef = useRef(null);
  const featuresRef = useRef(null);
  const howItWorksRef = useRef(null);
  const testimonialsRef = useRef(null);

  const scrollTo = (ref) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
    setMobileMenuOpen(false);
  };

  const features = [
    { 
      icon: CheckCircle, 
      title: "Improved Success Rate", 
      content: "Our model boosts confidence detection with a 43% margin over human detection alone."
    },
    { 
      icon: DollarSign, 
      title: "Save Money", 
      content: "Save 3-4x on a bad hire's salary by using our AI-powered system."
    },
    { 
      icon: BarChart2, 
      title: "Fast Analysis", 
      content: "Get quick feedback confidence levels in time to make your important hiring decisions."
    },
    
  ];

  const steps = [
    { icon: Upload, title: "Upload Interview", content: "Submit your interview recordings, recorded either online or in-person, to our secure platform." },
    { icon: FileText, title: "AI Transcription", content: "Our advanced AI creates a detailed transcript and analyzes your interview in real-time." },
    { icon: BarChart2, title: "Confidence Analysis", content: "Receive a comprehensive report with actionable insights to improve your interview performance." },
  ];

  const testimonials = [
    { name: "John Doe", role: "HR Manager, Fortune 500 Tech Company", quote: "Verilo has revolutionized our hiring process. We've seen a 40% reduction in bad hires since implementing their AI-powered system.", image: "/api/placeholder/80/80" },
    { name: "Jane Smith", role: "CEO, Leading StartUp Inc", quote: "The insights provided by Verilo's confidence detection have been invaluable. It's like having an expert interviewer by your side at all times.", image: "/api/placeholder/80/80" },
  ];

  const nextTestimonial = () => {
    setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentTestimonial((prev) => (prev - 1 + testimonials.length) % testimonials.length);
  };

  // Inside your component:
  const [isPlaying, setIsPlaying] = useState(false);

  const handleVideoClick = () => {
    const video = document.getElementById('verilo-video');
    if (video) {
      if (video.paused) {
        video.play();
        setIsPlaying(true);
      } else {
        video.pause();
        setIsPlaying(false);
      }
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100">
      <Navbar />

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <section ref={heroRef} className="py-16 md:py-24 flex flex-col md:flex-row items-center justify-between">
          <div className="md:w-1/2 md:pr-8 mb-12 md:mb-0">
            <h1 className="text-4xl md:text-5xl font-extrabold mb-6 leading-tight text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-purple-400">
              Boost Interview Success with AI-Powered Confidence Analysis
            </h1>
            <h2 className="text-2xl text-gray-300 mb-4">
              Verilo is an AI-powered interview analysis platform that helps companies make better hiring decisions by detecting candidate confidence levels during interviews.
            </h2>
            <p className="text-xl text-gray-300 mb-8">
              Our advanced AI analyzes interview recordings to provide detailed insights into candidate confidence, helping you identify the best talent and reduce costly bad hires.
            </p>
            <Link 
              to="/contact" 
              className="inline-block bg-gradient-to-r from-indigo-500 to-purple-600 text-white text-lg font-semibold py-3 px-6 rounded-lg hover:from-indigo-600 hover:to-purple-700 transition shadow-md"
            >
              Schedule a Demo
              <ArrowRight className="inline-block ml-2 h-5 w-5" />
            </Link>
          </div>
          <div className="md:w-1/2 md:pl-8">
            <div 
              className="relative cursor-pointer" 
              onClick={handleVideoClick}
            >
              <video
                id="verilo-video"
                className="w-full h-auto rounded-lg shadow-xl"
                poster="/video_thumbnail.png"
                onPause={() => setIsPlaying(false)}
                onPlay={() => setIsPlaying(true)}
              >
                <source src="videos/verilo_video.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              
              {!isPlaying && (
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-30">
                  <div className="bg-white bg-opacity-90 rounded-full p-4 transform transition-transform hover:scale-110">
                    <Play className="h-12 w-12 text-gray-900" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>

        <section ref={featuresRef} className="py-20">
          <h2 className="text-3xl font-bold text-center mb-12 text-gray-100">Why Choose Verilo?</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            {features.map((feature, index) => (
              <div key={index} className="bg-gray-800 rounded-lg p-8 shadow-lg hover:shadow-xl transition transform hover:-translate-y-1">
                <div className="bg-gradient-to-br from-indigo-500 to-purple-600 rounded-full p-3 inline-block mb-6">
                  <feature.icon className="h-8 w-8 text-white" />
                </div>
                <h3 className="text-xl font-semibold mb-4 text-gray-100">{feature.title}</h3>
                <p className="text-gray-300">{feature.content}</p>
              </div>
            ))}
          </div>
        </section>

        <section ref={howItWorksRef} className="py-20">
          <h2 className="text-3xl font-bold text-center mb-12 text-gray-100">How Verilo Works</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            {steps.map((step, index) => (
              <div key={index} className="bg-gray-800 rounded-lg p-8 shadow-lg hover:shadow-xl transition transform hover:-translate-y-1">
                <div className="bg-gradient-to-br from-indigo-500 to-purple-600 rounded-full p-3 mb-6 inline-block">
                  <step.icon className="h-8 w-8 text-white" />
                </div>
                <h3 className="text-xl font-semibold mb-4 text-gray-100">{step.title}</h3>
                <p className="text-gray-300">{step.content}</p>
              </div>
            ))}
          </div>
        </section>

        {/* <TestimonialsSection /> */}

        <section className="py-20 text-center">
          <h2 className="text-3xl font-bold mb-6 text-gray-100">Ready to Transform Your Interview Process?</h2>
          <p className="text-xl text-gray-300 mb-8 max-w-3xl mx-auto">Join the companies improving their hiring success and building stronger teams with Verilo.</p>
          <Link 
            to="/contact" 
            className="inline-block bg-gradient-to-r from-indigo-500 to-purple-600 text-white text-lg font-semibold py-3 px-8 rounded-lg hover:from-indigo-600 hover:to-purple-700 transition shadow-lg"
          >
            Schedule a Demo
            <ArrowRight className="inline-block ml-2 h-5 w-5" />
          </Link>
        </section>

        {/* <section className="py-10 border-t border-gray-800">
          <div className="flex flex-wrap justify-center items-center gap-8">
            <div className="flex items-center">
              <Shield className="h-6 w-6 text-indigo-400 mr-2" />
              <span className="text-gray-300">256-bit Encryption</span>
            </div>
            <div className="flex items-center">
              <CheckCircle className="h-6 w-6 text-indigo-400 mr-2" />
              <span className="text-gray-300">GDPR Compliant</span>
            </div>
            <Link to="/privacy-policy" className="text-indigo-400 hover:text-indigo-300">Privacy Policy</Link>
            <Link to="/terms-of-service" className="text-indigo-400 hover:text-indigo-300">Terms of Service</Link>
          </div>
        </section> */}
      </main>

      
    </div>
  );
};

export default LandingPage;